import React, { useState } from "react"
import Header from "../../components/Header"
import SideMenu from "../../components/SideMenu"
import "./Proposal.css"
import plus from "../../assets/plus-black.png"
import tag from "../../assets/tag-yellow.png"
import expand from "../../assets/expand.png"
import close from "../../assets/close.png"
import searchicon from "../../assets/search-icon.png"
import trash from "../../assets/trash.png"
import check from "../../assets/check-dark.png"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import mail from "../../assets/mail-blue.png"
import phonecall from "../../assets/phone-gray.png"
import address from "../../assets/address-gray.png"
import { useNavigate } from 'react-router-dom';
import Pagination from "../../components/Pagination/Pagination"
import AppModal from "../../components/Modal"

const Details = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [content, setContent] = useState('');
  const [search, setSearch] = useState('')
  const navigate = useNavigate();

  const handleClosePopup = () => {
    setShowPopup(false); // Close the pop-up when the user clicks the close button
  };

  const handleChange = (value) => {
    setContent(value);
  };
  // Dummy data for tabular display
  const businesses = [
    {
      id: 1,
      name: "ABC Enterprises",
      category: "Retail",
      location: "City A"
    },
    
  ]

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-content">
      <Header title="Proposal Templates"
      value={search}
      onChange={e => setSearch(e.target.value)}/>

        <div className="horizontal-rule"></div>
        <div className="content" style={{justifyContent: "space-between"}}>
          {/* Right Column */}
          <div className="right-columnsl" style={{maxWidth: "50%"}}>

          <div className="proposal-card">
                    <div className="proposal-content">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="proposal-image-container" style={{height: 50, width: 50}}>
                          
                        </div>
                        <div className="text-container">
                          <p className="proposal-card-title">
                            {"Business name"}
                          </p>
                          
                        </div>
                      </div>

                      
                      
                    </div>
                    <div className="proposal-buttonss" style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{}}>
            
            <div style={{display: "flex", alignItems: "center"}}>
            <img src={mail} alt={"button"} className="proposal-button-icon" style={{width: 14, height: 14}} />
              <p  style={{color: "black", fontSize: 12}}>Business Email</p>
              
            </div>
            <h3  style={{color: "black", fontSize: 16, marginTop: 5}}>Business@email.com</h3>
          </div>
          <div style={{}}>
            
            <div style={{display: "flex", alignItems: "center"}}>
            <img src={phonecall} alt={"button"} className="proposal-button-icon" style={{width: 14, height: 14}} />
              <p  style={{color: "black", fontSize: 12}}>Business Phone</p>
              
            </div>
            <h3  style={{color: "black", fontSize: 16, marginTop: 5}}>+1554648897</h3>
          </div>
          <div style={{}}>
            
            <div style={{display: "flex", alignItems: "center"}}>
            <img src={address} alt={"button"} className="proposal-button-icon" style={{width: 14, height: 14}} />
              <p  style={{color: "black", fontSize: 12}}>Business Address</p>
              
            </div>
            <h3  style={{color: "black", fontSize: 16, marginTop: 5}}>Frist Stree, LA</h3>
          </div>
                    </div>
                  </div>
           
            {businesses?.length
              ? businesses.map(item => (
                  <div className="proposal-card">
                    <div className="proposal-content">
                      <div style={{ display: "flex" }}>
                        <div className="proposal-image-container">
                          {/**<img src={proposal} alt={item.name} className="card-image" />**/}
                        </div>
                        <div className="text-container">
                          <p className="proposal-card-title">
                            {"Proposal name"}
                          </p>
                          <p className="card-description">
                            {
                              "Architecto consequatur molestias repellat qui. Quia est asd doloremque veniam est rerum. Soluta "
                            }
                          </p>
                        </div>
                      </div>
                      <div className="expand-icon">
                        <img
                          src={expand}
                          alt={item.name}
                          className="card-image"
                        />
                      </div>
                    </div>
                    <div className="proposal-buttons">
                      <button className="industry-button">
                        <img
                          src={tag}
                          alt={item.name}
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        Industry
                      </button>
                      <button onClick={() => setShowPopup(!showPopup)} className="template-button">Send Proposal</button>
                    </div>
                  </div>
                ))
              : null}

{showPopup && (
          <AppModal
          modalIsOpen={showPopup}
          proposal={true}
          closeModal={handleClosePopup}
          onSubmit={handleClosePopup}
        />
        
      )}

           
          </div>

          <div className="left-proposall" style={{flex: 1, marginLeft: 40, backgroundColor: "#fff", padding: 20}}>
          <div className="proposal-content">
                      <div style={{ display: "flex" }}>
                        
                        <div className="text-container">
                          <p className="proposal-card-title" style={{marginBottom: 15}}>
                            {"Preview"}
                          </p>
                          <p className="card-description" style={{marginBottom: 25}}>
                            {
                              "template"
                            }
                          </p>
                        </div>
                      </div>
                      <div className="expand-icon">
                        <img
                          src={trash}
                          alt={"item"}
                          className="card-image"
                        />
                        <img
                          src={expand}
                          alt={"item"}
                          className="card-image"
                        />
                        <img
                          src={close}
                          alt={"item"}
                          className="card-image"
                        />
                      </div>
                    </div>
          <button className="industry-button" style={{marginBottom: 30}}>
                        <img
                          src={tag}
                          alt={"item"}
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        Industry
                      </button>
          <p className="proposal-card-title" style={{fontSize: 24}}>
                            {"Proposal template name"}
                          </p>
          <div className="quill-editor">
      <ReactQuill value={content} onChange={handleChange} />
    </div>
    <div style={{justifyContent: "flex-end", marginTop: 30}}>
    <button className="template-button" onClick={() => navigate('/template')}>View Proposal</button>
    </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Details
