import React from "react"
import "./TitleData.css"
//import edit from '../../asetss/Edit.png'
//import flag from '../../asetss/flag.png'
////import trash from '../../asetss/trash.png'
import dot from "../../assets/side-dot.png"
import contract from "../../assets/contract-green.png"
import newbiz from "../../assets/new-red.png"
import proposal from "../../assets/proposal-yellow.png"
import contracticon from "../../assets/contract-icon.png"
import edit from "../../assets/edit.png"
import search from "../../assets/proposal-search.png"
import send from "../../assets/send.png"
import mail from "../../assets/email.png"
import phonecall from "../../assets/phone.png"
import address from "../../assets/address.png"
import plus from "../../assets/plus-black.png"
import sidedot from "../../assets/side-dots.png"
import moment from "moment"
import { Link } from "react-router-dom"

function DataLine({
  name,
  sn,
  image,
  email,
  image_url,
  navigate,
  joined,
  description,
  role,
  status,
  published,
  phone,
  location,
  checked,
  isChecked,
  item,
  deleteItem,
  editItem,
  link,
  message,
  state
}) {
  return (
    <div
    //style={{color: "#707178", textDecorationLine: "none"}}
    //state={state}
    //to={link}
    >
      <div className="horizontal-rule"></div>
      <div
        style={{
          backgroundColor: "#fff",
          borderLeft: checked === item.id ? "4px solid #0F5796" : null,
          boxShadow: checked === item.id ? "0 10px 20px rgba(0, 0, 0, 0.5)" : null
        }}
        className="titleLineBody"
      >
        <div className='checkBox' > <input type="checkbox" 
          checked={checked === item.id ? true : false}
            onChange={isChecked} /> </div> 

        <div style={{width: "26%"}} className="titleBoxData">
         {/** <input type="checkbox" 
          checked={checked === item.id ? true : false}
            onChange={isChecked} />  */}
          <p style={{ marginBottom: 20, marginLeft: 15, width: 180 }}>{item.name}</p>
        </div>

        <div style={{width: "30%"}}className="titleBoxData">
          {moment(item.created_at).format("lll")}
        </div>
        <div style={{width: "30%"}} className="titleBoxData">
          {item.id === 1 ? (
            <button
              className="all-button"
              style={{
                height: 30,
                marginBottom: 15,
                color: "green",
                backgroundColor: "#ddfada"
              }}
            >
              <img src={contract} alt="Button 1 Image" />
              Contract
            </button>
          ) : item.id === 2 ? (
            <button
              className="all-button"
              style={{
                backgroundColor: "#Ffccc5",
                color: "#FF5B5B",
                height: 30,
                marginBottom: 15
              }}
            >
              <img src={newbiz} alt="Button 1 Image" />
              New
            </button>
          ) : item.id === 8 ? (
            <button
              className="all-button"
              style={{
                backgroundColor: "#ffe8c6",
                color: "#FFBB54",
                height: 30,
                marginBottom: 15
              }}
            >
              <img src={proposal} alt="Button 1 Image" />
              Proposal
            </button>
          ) : (
            <button
              className="all-button"
              style={{
                backgroundColor: "#Ffccc5",
                color: "#FF5B5B",
                height: 30,
                marginBottom: 15
              }}
            >
              <img src={newbiz} alt="Button 1 Image" />
              New
            </button>
          )}
        </div>

        <div style={{}} className="titleBoxData">
          <Link>
            <img
              onClick={deleteItem}
              src={dot}
              alt=""
              className="titleIconEdit"
            />
          </Link>
        </div>
      </div>

      {checked === item.id ? (
        <div className="proposal-detail">
          <div className="proposal-detail-header">
            <div className="proposal-buttons-container">
              <div className="proposal-button-content">
                <img
                  src={contracticon}
                  alt={"button"}
                  className="proposal-button-icon"
                />
                <div className="proposal-button-info">
                  <h3 className="button-title">Create contract</h3>
                  <p className="button-description">Create new proposal</p>
                </div>
              </div>
              <div className="proposal-button-content">
                <img
                  src={search}
                  alt={"button"}
                  className="proposal-button-icon"
                />
                <div className="proposal-button-info" onClick={() => navigate('/proposals')}>
                  <h3 className="button-title">See proposal</h3>
                  <p className="button-description">See proposal</p>
                </div>
              </div>
              <div className="proposal-button-content">
                <img
                  src={search}
                  alt={"button"}
                  className="proposal-button-icon"
                />
                <div className="proposal-button-info" onClick={() => navigate('/contract')}>
                  <h3 className="button-title">See contract</h3>
                  <p className="button-description">See contract</p>
                </div>
              </div>
              <div className="proposal-button-content">
                <img
                  src={send}
                  alt={"button"}
                  className="proposal-button-icon"
                />
                <div className="proposal-button-info">
                  <h3 className="button-title">See contract</h3>
                  <p className="button-description">See contract</p>
                </div>
              </div>
            </div>
            <button
              className="all-button"
              style={{
                height: 40,
                marginBottom: 15,
                width: 100,
                color: "#0F5796",
                backgroundColor: "#fff"
              }}
            >
              <img src={edit} alt="Button 1 Image" />
              Edit
            </button>
          </div>
          <div className="horizontal-rule"></div>
          <div className="proposal-detail-section">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="proposal-image-container"
                style={{ height: 50 }}
              ></div>
              <p
                className="proposal-card-title"
                style={{ color: "white", marginBottom: 15 }}
              >
                Business name
              </p>
            </div>
            <div className="proposal-buttons-container">
              <div style={{}}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={mail}
                    alt={"button"}
                    className="proposal-button-icon"
                    style={{ width: 14, height: 14 }}
                  />
                  <p style={{ color: "white", fontSize: 12 }}>Business Email</p>
                </div>
                <h3 style={{ color: "white", fontSize: 16, marginTop: 5 }}>
                  Business@email.com
                </h3>
              </div>
              <div style={{}}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={phonecall}
                    alt={"button"}
                    className="proposal-button-icon"
                    style={{ width: 14, height: 14 }}
                  />
                  <p style={{ color: "white", fontSize: 12 }}>Business Phone</p>
                </div>
                <h3 style={{ color: "white", fontSize: 16, marginTop: 5 }}>
                  +1554648897
                </h3>
              </div>
              <div style={{}}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={address}
                    alt={"button"}
                    className="proposal-button-icon"
                    style={{ width: 14, height: 14 }}
                  />
                  <p style={{ color: "white", fontSize: 12 }}>
                    Business Address
                  </p>
                </div>
                <h3 style={{ color: "white", fontSize: 16, marginTop: 5 }}>
                  Frist Stree, LA
                </h3>
              </div>
            </div>
          </div>

          {item.id === 1 || item.id === 8 ?

          <div className="two-column-layout">
            <div className="proposal-left-column">
              <div className="details-section">
                <div className="left-columns">
                  <div className="detail-row">
                    <div className="detail-label">
                      When did you last speak to them?
                    </div>
                    <div className="detail-value">Email send</div>
                  </div>
                  <div className="detail-row">
                    <div className="detail-label">Add date</div>
                    <div className="detail-value">14:54 PM - 07/06/2022</div>
                  </div>
                  <div className="horizontal-rule"></div>
                  <div className="detail-rows">
                    <div
                      className="detail-label"
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      Who’s part of the deal?
                    </div>
                    <div
                      className="detail-value"
                      style={{ marginLeft: 20, marginBottom: 20 }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Placerat malesuada et viverra et consequat, commodo. Eget
                      dignissim elit, varius scelerisque elementum ultrices
                      ullamcorper sit. Scelerisque interdum ultricies at risus.
                    </div>
                  </div>
                  <div className="horizontal-rule"></div>
                  <div className="detail-rows">
                    <div className="detail-label" style={{ marginTop: 20 }}>
                      How long’s the deal taking to go through?
                    </div>
                    <div
                      className="detail-value"
                      style={{
                        marginLeft: 20,
                        marginBottom: 20,
                        marginTop: 20
                      }}
                    >
                      14 days
                    </div>
                  </div>
                  <div className="horizontal-rule"></div>
                  <div className="note-section">
                    <div className="add-note">
                      <textarea placeholder="Write your note here..." />
                      <button className="save-button">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="proposal-right-column">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <h3
                  style={{
                    color: "#0F5796",
                    fontSize: 24,
                    marginTop: 5,
                    marginBottom: 5
                  }}
                >
                  Notes
                </h3>
                <img
                  src={plus}
                  alt={"button"}
                  className="proposal-button-icon"
                  style={{ width: 24, height: 24 }}
                />
              </div>
              <p style={{ marginTop: 5, fontSize: 14 }}>
                Maiores dicta atque dolore
              </p>
              <div className="note">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: 14, marginBottom: 5 }}>
                      Note subject
                    </h5>
                    <p style={{ fontSize: 12, marginTop: 5 }}>Date</p>
                  </div>
                  <img
                    src={sidedot}
                    alt={"button"}
                    className="proposal-button-icon"
                    style={{ width: 14, height: 14 }}
                  />
                </div>
                <h5 style={{ marginBottom: 5, marginTop: 5 }}>
                  Change our contact at Homepage
                </h5>
                <p style={{ fontSize: 12, marginTop: 5 }}>
                  Description: Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit.
                </p>
                <button className="view-button" onClick={() => navigate('/note')}>View</button>
              </div>
              <div className="note">{/* Repeat for other notes */}</div>
              <div className="horizontal-rule"></div>
              <div className="note">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: 14, marginBottom: 5 }}>
                      Note subject
                    </h5>
                    <p style={{ fontSize: 12, marginTop: 5 }}>Date</p>
                  </div>
                  <img
                    src={sidedot}
                    alt={"button"}
                    className="proposal-button-icon"
                    style={{ width: 14, height: 14 }}
                  />
                </div>
                <h5 style={{ marginBottom: 5, marginTop: 5 }}>
                  Change our contact at Homepage
                </h5>
                <p style={{ fontSize: 12, marginTop: 5 }}>
                  Description: Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit.
                </p>
                <button className="view-button" onClick={() => navigate('/note')}>View</button>
              </div>
              <div className="note">{/* Repeat for other notes */}</div>
            </div>
          </div> : null }
        </div>
      ) : null}
    </div>
  )
}
export default DataLine
