import React, { useState } from 'react';
import './Login.css'; 
import eye from "../../assets/eye.png"
import {
  Link
} from "react-router-dom"
import { useDispatch } from 'react-redux';
import { login } from '../../redux/auth/actions';


const Login = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    // Handle the login logic here, e.g., submit the login credentials to the server
    const values = {
      username: email,
      password: password
    }
    dispatch(login(values))
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword); 
  };

  return (
    <div className="login-screen">
      <header className="header-login">
      <h1>Logo</h1>
        <span className="create-account-text">Don’t have an account? <span className="create-text"><Link to="/signup-choose"><a href="#">Create</a></Link></span></span>
      </header>
      <div className="form-container">
        <form onSubmit={handleLogin} className="login-form">
          <h2>Login to your account</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, ac ac turpis viverra</p>
          <div className="form-group">
            
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email" 
              required
            />
          </div>
          <div className="form-group">
           
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password" 
              required
            />
            <img
                        src={eye}
                        className="eye-icon"
                        alt="eye"
                        onClick={togglePasswordVisibility}
                      />
          </div>
          <button className="login-button" type="submit">Login</button>
          <p className="forgot-password-link">
          <Link to="/forgot-password"><a href="#">Forgot your password?</a></Link>
      </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
