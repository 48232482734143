import React from 'react';
import './TitleData.css';

function TitleLine({ titles }) {
  const defaultName = titles ? titles :  ['Business Name', 'Date', 'Status', 'See Details' ] 
  return ( 
      <div className='titleLineBody'>
          
         {
               defaultName.map((item) => 
               <div style={{width: item==='See Details' ? "10%" : "30%", alignItems: "center" }} className='titleBox'>
                 {item==='Business Name' ? 
                 <div className='checkBox' style={{marginRight: 15, marginTop: 10}}> <input type='checkbox' /></div> 
                 : null}
               <p style={{ color: "#A3A3A3", fontSize: 16}}>{item}</p>
               {/*<img src={down}  alt='' className='titleIcon'/>*/}
               </div>
               )
         }
      </div>
  );
}
export default TitleLine;