import React, { useState } from "react"
import "./Login.css"
import home from "../../assets/home.png"
import check from "../../assets/checkout.png"
import plus from "../../assets/plus.png"
import upload from "../../assets/upload-red.png"
import client from "../../assets/clients.png"
import media from "../../assets/media.png"
import staff from "../../assets/staff.png"
import chevron from "../../assets/chevron-right.png"
import chevronleft from "../../assets/chevron-left.png"
import { Link } from "react-router-dom"

const Onboarding = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const handleNext = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }

  const handlePrevious = () => {
    setCurrentPage(prevPage => prevPage - 1)
  }

  return (
    <div className="onboarding-screen">
      <header
        className="header-logi"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <span className="create-account-text">
          Need help?{" "}
          <span className="create-text">
            <Link to="/signup-choose">See FAQ</Link>
          </span>
        </span>
      </header>

      <div className="onboarding-container">
        <div className="left-column">
          <div className="header-onboarding">
            <img src={home} alt="Header Image" />
            <div>
              <h2>Client onboarding </h2>

              <div className="left-column-buttons">
                <button>
                  <img src={client} alt="Button 1 Image" />
                  Clients
                </button>
                <button>
                  <img src={media} alt="Button 1 Image" />
                  Media list
                </button>
                <button>
                  <img src={staff} alt="Button 1 Image" />
                  Staff
                </button>
              </div>
            </div>
          </div>
          {currentPage === 1 && (
            <div className="survey-inputs">
              <label>
                1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
                tellus sed?
              </label>
              <input type="text" placeholder="Enter your answer" />
              <label>
                2. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
                tellus sed?
              </label>
              <input type="text" placeholder="Enter your answer" />
              <label>
                3. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
                tellus sed?
              </label>
              <input type="text" placeholder="Enter your answer" />
              <label>
                4. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
                tellus sed?
              </label>
              <input type="text" placeholder="Enter your answer" />

              {/** <div className="navigation-buttons">
            <img src={chevronleft} onClick={handleNext} alt="Button 1 Image" />
            <img src={chevron} onClick={handleNext} alt="Button 1 Image" />
             
        </div> **/}
            </div>
          )}
          {/** currentPage === 2 && (
          <div className="survey-inputs">
            {/* Add inputs for the second page of surveys */
          /*}
            <div className="navigation-buttons">
              <button onClick={handlePrevious}>Previous</button>
              <button onClick={handleNext}>Next</button>
            </div>
          </div>
        ) **/}
          <div className="bottom-buttons">
            <Link to="/" style={{textDecorationLine: "none"}}>
              <button onClick={handleNext} className="btn-finish">
                Finish
              </button>
            </Link>
            <button onClick={handleNext} className="btn-skip">
              Skip
              <img src={chevron} alt="Button 1 Image" />
            </button>
          </div>
        </div>
        <div className="right-column" style={{ marginTop: 40 }}>
          <div className="right-column-buttons">
            <button>
              <img src={plus} alt="Button 1 Image" />
              Campaign
            </button>
            <button
              className="checkout"
              style={{ backgroundColor: "#F0F0F0", color: "#0F5796" }}
            >
              <img src={check} alt="Button 2 Image" />
              Check out
            </button>
            <button style={{ backgroundColor: "#FFBCBC", color: "#FF5B5B" }}>
              <img src={upload} alt="Button 3 Image" />
              Upload logo
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Onboarding
