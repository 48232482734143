import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { colors } from "../utils/colors"
import close from "../assets/close.png"
import send from "../assets/send-white.png"
import later from "../assets/draft.png"
import eye from "../assets/eye.png"
import tag from "../assets/tag-yellow.png"
import expand from "../assets/expand.png"

import moment from "moment"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: 10,
    backgroundColor: "#0F5796",
    transform: "translate(-50%, -50%)",
  }
}

const Input = ({
  height,
  type,
  title,
  value,
  onChange,
  placeholder,
  password,
  onClick,
  textArea,
  margin,
  width,
  borderColor
}) => {
  return (
    <div style={{}}>
      {!!title && (
        <h5
          style={{
            color: "black"
          }}
        >
          {title}
        </h5>
      )}
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "white",
          //width: width ? width : "95%",
          padding: 10,
          marginLeft: margin ? margin : null,
          height: height ? height : 45,
          borderRadius: 5,
          border: "1px solid #E5e5e5",
          borderWidth: 2,
          alignItems: "center"
        }}
      >
        <input
          style={{
            width: width ? width : "90%",
            height: "100%",
            borderWidth: borderColor ? 1 : 0,
            outline: "none",
            alignItems: "center"
          }}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {password ? (
          <img
            onClick={onClick}
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
            src={eye}
            alt=""
            width="24"
            height="24"
          />
        ) : null}
      </div>
    </div>
  )
}

function AppModal({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  values,
  onChangeText,
  onSubmit,
  onLogout,
  proposal,
  reset,
  signup
}) {
  
  const [show, hide] = useState(false)


  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
       

        {reset ? (
          <div style={{
            backgroundColor: "#0F5796",
            padding: 20,
            paddingBottom: "10%",
            paddingTop: "10%"

          }}>
          <div style={{
            maxWidth: 500
          }}>
            <h3 style={{
                textAlign: "center",
                color: colors.white,
                fontSize: 40,
                margin: 5
              }}>Reset password</h3>
            <p style={{
                textAlign: "center",
                color: colors.white,
                fontSize: 16,
                marginBottom: "10%"
               
              }}>We send you an email with verification link!
              Please check {values}</p>
            <button onClick={closeModal}
            style={{
              display: "block",
              width: "100%",
              height: 80,
              border: "none",
            //  padding: 10px;
              backgroundColor: '#fff',
              color: "#0F5796",
              fontSize: 26,
              cursor: 'pointer'
             // margin-top: 10%;
             // border: none;
             // border-radius: 3px;
             // cursor: pointer;
            }}
            >Continue</button>
          </div>
        </div>
        ) : null}

{proposal ? (
  <>
          <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#0F5796",
            padding: 20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20
          }}
        >
          <p
            className="proposal-card-title"
            style={{ fontSize: 24, color: "#fff" }}
          >
            {"Send Proposal"}
          </p>
          <img
            src={close}
            onClick={closeModal}
            alt="Button 1 Image"
            style={{ width: 20, height: 20 }}
          />
        </div>
        <div style={{ backgroundColor: "#fff", padding: 20 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 20
            }}
          >
            <p
              className="proposal-card-title"
              style={{ fontSize: 16, color: "#0F5796", marginRight: 20 }}
            >
              {"Email:"}
            </p>
            <div className="search-dropdown">
              <div className="input-container">
                <input type="text" placeholder="clientemail@email.com" />
              </div>
              {/**selectedItem && <p>Selected: {selectedItem}</p>**/}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 20
            }}
          >
            <p
              className="proposal-card-title"
              style={{ fontSize: 16, color: "#0F5796", marginRight: 20 }}
            >
              {"CC:"}
            </p>
            <div className="search-dropdown">
              <div className="input-container">
                <input type="text" placeholder="Additional email" />
              </div>
              {/**selectedItem && <p>Selected: {selectedItem}</p>**/}
            </div>
          </div>

          <div  style={{}}>
          <div className="proposal-card" style={{width: 500, padding: 20, marginLeft: 40, marginTop: 40, boxShadow: " 0px 0px 10px rgba(0, 0, 0, 0.5)"}}>
                    <div className="proposal-content">
                      <div style={{ display: "flex" }}>
                        <div className="proposal-image-container">
                          {/**<img src={proposal} alt={item.name} className="card-image" />**/}
                        </div>
                        <div className="text-container">
                          <p className="proposal-card-title">
                            {"Proposal name"}
                          </p>
                          <p className="card-description">
                            {
                              "Architecto consequatur molestias repellat qui. Quia est asd doloremque veniam est rerum. Soluta "
                            }
                          </p>
                        </div>
                      </div>
                      <div className="expand-icon">
                        <img
                          src={expand}
                          alt={"name"}
                          className="card-image"
                        />
                      </div>
                    </div>
                    <div className="proposal-buttons">
                      <button className="industry-button">
                        <img
                          src={tag}
                          alt={"name"}
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        Industry
                      </button>
                      <button className="template-button">Send Proposal</button>
                    </div>
                  </div>
           
          </div>
          <div style={{display: "flex", justifyContent: "space-between", marginTop: 30 }}>
            <button
              className="template-button"
              style={{ backgroundColor: "#fff", color: "#0F5796" }}
            >
              <img
                src={later}
                alt="Button 1 Image"
                style={{ width: 18, height: 18, marginRight: 5, marginTop: 20 }}
              />
              Save for later
            </button>
            <button
              className="template-button"
              style={{ backgroundColor: "#0F5796", color: "#fff", height: 40, marginTop: 20 }}
            >
              <img
                src={send}
                alt="Button 1 Image"
                style={{ width: 14, height: 14, marginRight: 5 }}
              />
              Send
            </button>
          </div>
        </div>
        </>
        ) : null}


{signup ? (
          <div style={{
            backgroundColor: "#0F5796",
            padding: 20,
            paddingBottom: "10%",
            paddingTop: "10%"

          }}>
          <div style={{
            maxWidth: 500
          }}>
            <h3 style={{
                textAlign: "center",
                color: colors.white,
                fontSize: 40,
                margin: 5
              }}>Verification Required</h3>
            <p style={{
                textAlign: "center",
                color: colors.white,
                fontSize: 16,
                marginBottom: "10%"
               
              }}>We send you an email with verification link!
              Please check {values.email}</p>
            <button onClick={closeModal}
            style={{
              display: "block",
              width: "100%",
              height: 80,
            //  padding: 10px;
              backgroundColor: "#fff",
              color: "#0F5796",
              fontSize: 26,
             // margin-top: 10%;
             // border: none;
             // border-radius: 3px;
             // cursor: pointer;
            }}
            >Continue</button>
          </div>
        </div>
        ) : null}

    

        {onLogout ? (
          <>
            <h3
              style={{
                textAlign: "center",
                color: colors.main
              }}
              onClick={closeModal}
            >
              Sign Out
            </h3>

            <div style={{ marginBottom: 20 }}>
              <p>Are you sure you want to Sign Out?</p>
            </div>

            <div style={{ marginTop: 20, alignSelf: "center" }}>
           
              <h4
                style={{
                  textAlign: "center",
                  color: colors.main
                }}
                onClick={closeModal}
              >
                Cancel
              </h4>
            </div>
          </>
        ) : null}
      </Modal>
    </div>
  )
}
export default AppModal
