import React, { useState } from 'react';
import AppModal from '../../components/Modal';
import './Login.css'; 
import agency from "../../assets/agency.png"
import house from "../../assets/house.png"
import {
    Link
  } from "react-router-dom"


const SignupChoose = () => {
  const [email, setEmail] = useState('');
  const [showPopup, setShowPopup] = useState(false);


  const handleLogin = (e) => {
    e.preventDefault();

    // Handle the login logic here, e.g., submit the login credentials to the server
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the pop-up when the user clicks the close button
  };

  return (
    <div className="login-screen">
      <header className="header-login">
      <h1>Logo</h1>
        
      </header>

      <h3 className="choose-title">Choose your user type</h3>
     
      <div className="cards-container">
      <Link to="/signup" style={{ textDecoration: 'none' }}><div className="card">
        <img src={agency} alt="Agency" />
        <h3>Agency</h3>
      </div></Link>
      <div className="or-button-container">
        <button className="or-button">OR</button>
      </div>
      <Link to="/signup" style={{ textDecoration: 'none' }}><div className="card">
        <img src={house} alt="In-house" />
        <h3>In House</h3>
      </div></Link>
    </div>

    </div>
  );
};

export default SignupChoose;
