import React from "react"
import "./Home.css"
import landing from "../../assets/right_landing_page.png"
import check from "../../assets/land-check.png"
import checkicon from "../../assets/check-white.png"
import checkblue from "../../assets/check-blue.png"
import chevrondown from "../../assets/chevron-down.png"
import {
  Link
} from "react-router-dom"

const Home = () => {
  const scrollToFooter = () => {
    const footer = document.querySelector('footer');
    footer.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="landing-page">
      <header>
        <h1>Logo</h1>
      </header>
      <section>
        <div className="section-content">
          <div className="cta">
            <h2>Scelerisque Platea Eget At Et Elementum Sed.</h2>
            <p>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.{" "}
            </p>
            <div className="btn-container">
            <Link to="/signup-choose"><button className="btn-signup">Sign Up</button></Link>
              <Link to="/login"><button className="btn-login">Log In</button></Link>
            </div>
          </div>
          <div className="image-container">
            <img src={landing} className="img-right" alt="landing" />
          </div>
        </div>
      </section>
      <div className="scroll-icon" onClick={scrollToFooter}>
      <img src={chevrondown} className="img-right" alt="Scroll Down" />
      </div>
      <section>
        <div className="section-header">
          <h2>Aplication Description</h2>
          <p>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit.
          </p>
        </div>
        <div className="second-section">
          <div className="home-card">
            <img src={check} className="img-check" alt="landing" />
            <h2>High Quality</h2>
            <p>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet.
            </p>
            <div className="card-image"></div>
          </div>
          <div className="home-card">
            <img src={check} className="img-check" alt="landing" />
            <h2>Easy to Use</h2>
            <p>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet.
            </p>
            <div className="card-image"></div>
          </div>
          <div className="home-card">
            <img src={check} className="img-check" alt="landing" />
            <h2>Fast Service</h2>
            <p>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet.
            </p>
            <div className="card-image"></div>
          </div>
        </div>
      </section>
      <section className="third-section">
        <div className="section-content">
          <div className="general-info">
            <h2>Choose Your Plan</h2>
            <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
            </p>
          </div>
          <div className="subscription-plans">
            <div className="subscription-plan-overlay subscription-plan-top blue-plan">
              <h3 className="plan-title">Monthly</h3>
              <p className="price">$399</p>
              <button className="btn-subscribe">Subscribe</button>
              <div className="description">
                <ul>
                  <li>
                    <span className="checklist-icon">
                      <img
                        src={checkicon}
                        className="list-icon"
                        alt="landing"
                      />
                    </span>{" "}
                    Lorem ipsum dolor sit amet
                  </li>
                  <li>
                    <span className="checklist-icon">
                      {" "}
                      <img
                        src={checkicon}
                        className="list-icon"
                        alt="landing"
                      />
                    </span>{" "}
                    Lorem ipsum dolor sit amet
                  </li>
                  <li>
                    <span className="checklist-icon">
                      {" "}
                      <img
                        src={checkicon}
                        className="list-icon"
                        alt="landing"
                      />
                    </span>{" "}
                    Lorem ipsum dolor sit amet
                  </li>
                  <li>
                    <span className="checklist-icon">
                      {" "}
                      <img
                        src={checkicon}
                        className="list-icon"
                        alt="landing"
                      />
                    </span>{" "}
                    Lorem ipsum dolor sit amet
                  </li>
                </ul>
              </div>
            </div>
            <div className="subscription-plan-overlay subscription-plan-bottom">
            <h3 className="plan-title white-plan">Monthly</h3>
              <p className="price white-plan">$599</p>
              <button className="btn-subscribe btn">Subscribe</button>
              <div className="description">
                <ul>
                  <li className="desc">
                    <span className="checklist-icon">
                      <img
                        src={checkblue}
                        className="list-icon"
                        alt="landing"
                      />
                    </span>{" "}
                    Lorem ipsum dolor sit amet
                  </li>
                  <li className="desc">
                    <span className="checklist-icon">
                      {" "}
                      <img
                        src={checkblue}
                        className="list-icon"
                        alt="landing"
                      />
                    </span>{" "}
                    Lorem ipsum dolor sit amet
                  </li>
                  <li className="desc">
                    <span className="checklist-icon">
                      {" "}
                      <img
                        src={checkblue}
                        className="list-icon"
                        alt="landing"
                      />
                    </span>{" "}
                    Lorem ipsum dolor sit amet
                  </li>
                  <li className="desc">
                    <span className="checklist-icon">
                      {" "}
                      <img
                        src={checkblue}
                        className="list-icon"
                        alt="landing"
                      />
                    </span>{" "}
                    Lorem ipsum dolor sit amet
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="footer-left">
        <h1>Logo</h1>
          <p>&copy; Copyright © 2023. By Agency.  All rights reserved.</p>
        </div>
        <div className="footer-right">
          <a href="#">Terms and Conditions</a>
          <a href="#">Privacy Policy</a>
          <a href="#">Contact Info</a>
        </div>
      </footer>
    </div>
  )
}

export default Home
