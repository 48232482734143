import React, { useState } from "react"
import Header from "../../components/Header"
import SideMenu from "../../components/SideMenu"
import "./Contract.css"
import plus from "../../assets/plus-black.png"
import tag from "../../assets/tag-yellow.png"
import expand from "../../assets/expand.png"
import close from "../../assets/close.png"
import searchicon from "../../assets/search-icon.png"
import trash from "../../assets/trash.png"
import check from "../../assets/check-dark.png"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

import Pagination from "../../components/Pagination/Pagination"

const Contract = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(6)
  const [content, setContent] = useState('');
  const [search, setSearch] = useState('')

  const handleChange = (value) => {
    setContent(value);
  };
  // Dummy data for tabular display
  const businesses = [
    {
      id: 1,
      name: "ABC Enterprises",
      category: "Retail",
      location: "City A"
    },
    {
      id: 1,
      name: "ABC Enterprises",
      category: "Retail",
      location: "City A"
    },
    {
      id: 1,
      name: "ABC Enterprises",
      category: "Retail",
      location: "City A"
    }
  ]

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-content">
      <Header title="Contract Templates"
      value={search}
      onChange={e => setSearch(e.target.value)}/>

        <div className="horizontal-rule"></div>
        <div className="content" style={{justifyContent: "space-between"}}>
          {/* Right Column */}
          <div className="right-columnsl" style={{maxWidth: "50%"}}>
            <div style={{ display: "flex", marginBottom: 20 }}>
              <div
                style={{
                  flex: 2,

                  display: "flex",
                  alignItems: "center",
                  position: "relative"
                }}
              >
                <input
                  type="text"
                  placeholder="Search..."
                  style={{
                    width: "100%",
                    height: 26,
                    padding: "8px 30px 8px 8px",
                    border: "none",
                    borderRadius: "10px",
                    backgroundColor: "#F0F0F0"
                  }}
                />
                <img
                  src={searchicon}
                  alt="Search Icon"
                  style={{
                    position: "absolute",
                    width: 20,
                    height: 20,
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20
                }}
              >
                <div class="vertical-rule"></div>
                <div
                  style={{
                    marginLeft: "20px",
                    textAlign: "center",
                    
                    cursor: "pointer"
                  }}
                >
                  <img
                    src={plus}
                    alt="Notification Icon"
                    style={{ width: 18, height: 18, marginRight: 10 }}
                  />
                  <img
                    src={trash}
                    alt="Notification Icon"
                    style={{ width: 18, height: 18, marginRight: 10 }}
                  />
                  <img
                    src={check}
                    alt="Notification Icon"
                    style={{ width: 18, height: 18, }}
                  />
                </div>
              </div>
            </div>

            {businesses?.length
              ? businesses.map(item => (
                  <div className="proposal-card">
                    <div className="proposal-content">
                      <div style={{ display: "flex" }}>
                        <div className="proposal-image-container">
                          {/**<img src={proposal} alt={item.name} className="card-image" />**/}
                        </div>
                        <div className="text-container">
                          <p className="proposal-card-title">
                            {"Proposal template name"}
                          </p>
                          <p className="card-description">
                            {
                              "Architecto consequatur molestias repellat qui. Quia est asd doloremque veniam est rerum. Soluta "
                            }
                          </p>
                        </div>
                      </div>
                      <div className="expand-icon">
                        <img
                          src={expand}
                          alt={item.name}
                          className="card-image"
                        />
                      </div>
                    </div>
                    <div className="proposal-buttons">
                      
                      <button className="template-button">Use Template</button>
                    </div>
                  </div>
                ))
              : null}

            <Pagination
              //className="pagination-bar"
              currentPage={currentPage}
              totalCount={businesses.length}
              pageSize={pageSize}
              onPageChange={page => setCurrentPage(page)}
              onSizeChange={size => setPageSize(size)}
            />
          </div>

          <div className="left-proposall" style={{flex: 1, marginLeft: 40, backgroundColor: "#fff", padding: 20}}>
          <div className="proposal-content">
                      <div style={{ display: "flex" }}>
                        
                        <div className="text-container">
                          <p className="proposal-card-title" style={{marginBottom: 15}}>
                            {"Preview"}
                          </p>
                          <p className="card-description" style={{marginBottom: 25}}>
                            {
                              "template"
                            }
                          </p>
                        </div>
                      </div>
                      <div className="expand-icon">
                        <img
                          src={trash}
                          alt={"item"}
                          className="card-image"
                        />
                        <img
                          src={expand}
                          alt={"item"}
                          className="card-image"
                        />
                        <img
                          src={close}
                          alt={"item"}
                          className="card-image"
                        />
                      </div>
                    </div>
          
          <p className="proposal-card-title" style={{fontSize: 24}}>
                            {"Contract template name"}
                          </p>
          <div className="quill-editor">
      <ReactQuill value={content} onChange={handleChange} />
    </div>
    <div style={{justifyContent: "flex-end", marginTop: 30}}>
    <button className="template-button" >Use Template</button>
    </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contract
