import React from 'react';
//import { usePagination } from './usePagination';
import prevIcon from '../../assets/pageleft.png'; 
import nextIcon from '../../assets/pageright.png'; 
import './pagination.css';
const Pagination = ({
  onPageChange,
  onSizeChange,
  totalCount,
  siblingCount,
  currentPage,
  DOTS,
  create,
  totalPages,
  pageSize,
  className
}) => {
  //const paginationRange = usePagination({
  //  currentPage,
  //  totalCount,
  //  siblingCount,
  //  pageSize
  //});

  // If there are less than 2 times in pagination range we shall not render the component
  //if (currentPage === 0 || paginationRange.length < 2) {
  //  return null;
 // }

  const onNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
      //onSizeChange(pageSize + 5)
    } else {
      //onPageChange(currentPage);
    }
  };

  const onPrevious = () => {
    if (currentPage > 1) {
      //onPageChange(currentPage);
      onPageChange(currentPage - 1);
      //onSizeChange(pageSize - 5)
    } else {
      //onPageChange(currentPage - 5);
      //onSizeChange(pageSize - 5)
    }

  };

  //let lastPage = paginationRange[paginationRange.length - 1];
  
  return (
    <div style={{marginTop: 40, paddingBottom: 40}}>
     
      <div className="pagination-container">
        {!create ?
      <div>
        <p style={{color: "#0F5796", fontSize: 16}}>Showing {pageSize} from {totalCount} data</p>
      </div>
      : null }
      <div>
      <div className="pagination">
      <img src={prevIcon} onClick={onPrevious} alt="Previous" className="icon" />
      { Array.from({ length: 4 }, (_, index) => (Math.floor((currentPage - 1) / 4) * 4) + index + 1).map((pageNumber) => (
        <span
          key={pageNumber}
          className={`page-number ${pageNumber === currentPage ? 'current-page' : ''}`}
        >
          {pageNumber}
        </span>
      ))}
      <img src={nextIcon} onClick={onNext} alt="Next" className="icon" />
    </div>
       
      </div>
      </div>
    </div>
  );
};

export default Pagination;