import React from 'react';
import staff from "../assets/staff-icon.png"
import client from "../assets/client-icon.png"
import campaign from "../assets/campaign-icon.png"
import business from "../assets/new-icon.png"
import { Link, useLocation } from 'react-router-dom';

const SideMenu = () => {
  const sideMenuStyle = {
    width: '250px',
    backgroundColor: '#0F5796',
    color: '#fff',
    padding: '20px',
    height: '100vh',
    position: 'fixed', 
  };

  const logoStyle = {
    display: 'block',
    margin: '0 auto 20px', 
    color: "#fff",
    width: '100px', // Set the width of your logo
  };

  const titleStyle = {
    fontSize: '0.8rem', // Set the font size for the title
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 5, 
    color: "#ccc"
  };

  const hrStyle = {
    borderTop: '1px solid #555',
    margin: '20px 0',
  };

  const menuItemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    marginTop: 40,
    cursor: 'pointer',
  };

  const iconStyle = {
    marginRight: '10px',
    width: '20px',
    height: '20px',
  };

  const location = useLocation();

  return (
    <div style={sideMenuStyle}>
      <h1 style={logoStyle}>Logo</h1>
      <div style={titleStyle}>AGENCY MANAGMENT</div>
      <hr style={hrStyle} />
      <div style={{...menuItemStyle, backgroundColor: location.pathname === '/dashboard' ? 'gray'  : null}}>
        <img
          src={staff}
          alt="Home Icon"
          style={iconStyle}
        />
        <Link style={{
         color: location.pathname === '/staff' ? '#fff'  : '#fff', textDecorationLine: "none", cursor: "pointer"
      }}  to='/' className='iconText'>Staff management</Link>
      </div>
      <div style={{...menuItemStyle, backgroundColor: location.pathname === '/dashboard' ? 'gray'  : null}}>
        <img
          src={client}
          alt="Profile Icon"
          style={iconStyle}
        />
       <Link style={{
         color: location.pathname === '/client' ? '#fff'  : '#fff', textDecorationLine: "none", cursor: "pointer"
      }}  to='/' className='iconText'>Client management</Link>
      </div>
      <div style={{...menuItemStyle, backgroundColor: location.pathname === '/dashboard' ? 'gray'  : null}}>
        <img
          src={campaign}
          alt="Settings Icon"
          style={iconStyle}
        />
       <Link style={{
         color: location.pathname === '/campaign' ? '#fff'  : '#fff', textDecorationLine: "none", cursor: "pointer"
      }}  to='/' className='iconText'>Campaign management</Link>
      </div>
      <div style={{...menuItemStyle, backgroundColor: location.pathname === '/' ? '#6f9ac0'  : null, padding: location.pathname === '/' ? 10  : null, borderRadius: location.pathname === '/' ? 5  : null}}>
        <img
          src={business}
          alt="Messages Icon"
          style={iconStyle}
        />
        <Link style={{
         color: location.pathname === '/' ? '#fff'  : '#fff', textDecorationLine: "none", cursor: "pointer"
      }}  to='/' className='iconText'>New business management</Link>
      </div>
      
      <hr style={hrStyle} />
    </div>
  );
};

export default SideMenu;
