import React, { useEffect, useState, useRef } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import "./App.css"
import Login from "./screens/Login/Login"

import { setUser } from "./redux/auth/actions"
import StorageUtils from "./utils/storage"
import { addTokenToHttp } from "./utils/http"
import { getProfile } from "./redux/app/actions"
import Home from "./screens/Home/Home"
import Reset from "./screens/Login/Reset"
import SignupChoose from "./screens/Login/SignupChoose"
import SignUp from "./screens/Login/SignUp"
import Onboarding from "./screens/Login/Onboarding"
import Dashboard from "./screens/Dashboard/Dashboard"
import Proposal from "./screens/Proposals/Proposal"
import NewProposal from "./screens/Proposals/NewProposal"
import Template from "./screens/Proposals/Template"
import AddBusiness from "./screens/Dashboard/AddBusiness"
import Note from "./screens/Dashboard/Note"
import Contract from "./screens/Contract/Contract"
import Details from "./screens/Proposals/Details"

function Navigation() {
  let token = useRef()
  const dispatch = useDispatch()
  const accessToken = useSelector(state => state.Auth.accessToken)

  useEffect(() => {
    ;(async () => {
      token.current = await StorageUtils.getAccessToken()
      dispatch(setUser({}, token?.current))
      addTokenToHttp(token?.current)
      if (accessToken) {
        dispatch(getProfile())
      }
    })()
  }, [])

  const isLogggedIn = Boolean(accessToken || token?.current)

  //console.log(accessToken)

  return (
    <Router>
      {isLogggedIn ? (
        <Routes>
          <Route path="/onboard" element={<Onboarding />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/*" element={<Onboarding />} />
          <Route path="/proposals" element={<Proposal />} />
          <Route path="/create-proposal" element={<NewProposal />} />
          <Route path="/template" element={<Template />} />
          <Route path="/add-business" element={<AddBusiness />} />
          <Route path="/note" element={<Note />} />
          <Route path="/contract" element={<Contract />} />
          <Route path="/doc-details" element={<Details />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Reset />} />
          <Route path="/signup-choose" element={<SignupChoose />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      )}
    </Router>
  )
}
export default Navigation
