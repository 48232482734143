export const APP_CHANGE_LOADING_STATE = 'APP/CHANGE_LOADING_STATE';
export const APP_CLEAR_STATE = 'APP/CLEAR_STATE';

export const APP_GET_PROFILE_REQUEST = 'APP/GET_PROFILE/REQUEST';
export const APP_GET_PROFILE_SUCCESS = 'APP/GET_PROFILE/SUCCESS';
export const APP_GET_PROFILE_ERROR = 'APP/GET_PROFILE/ERROR';

export const APP_UPDATE_PROFILE_REQUEST = 'APP/UPDATE_PROFILE/REQUEST';
export const APP_UPDATE_PROFILE_SUCCESS = 'APP/UPDATE_PROFILE/SUCCESS';
export const APP_UPDATE_PROFILE_ERROR = 'APP/UPDATE_PROFILE/ERROR';

export const APP_GET_USERS_REQUEST = 'APP/GET_USERS/REQUEST';
export const APP_GET_USERS_SUCCESS = 'APP/GET_USERS/SUCCESS';
export const APP_GET_USERS_ERROR = 'APP/GET_USERS/ERROR';

export const APP_DELETE_USER_REQUEST = 'APP/DELETE_USER/REQUEST';
export const APP_DELETE_USER_SUCCESS = 'APP/DELETE_USER/SUCCESS';
export const APP_DELETE_USER_ERROR = 'APP/DELETE_USER/ERROR';

export const APP_UPDATE_USER_REQUEST = 'APP/UPDATE_USER/REQUEST';
export const APP_UPDATE_USER_SUCCESS = 'APP/UPDATE_USER/SUCCESS';
export const APP_UPDATE_USER_ERROR = 'APP/UPDATE_USER/ERROR';

export const APP_GET_FEEDBACK_REQUEST = 'APP/GET_FEEDBACK/REQUEST';
export const APP_GET_FEEDBACK_SUCCESS = 'APP/GET_FEEDBACK/SUCCESS';
export const APP_GET_FEEDBACK_ERROR = 'APP/GET_FEEDBACK/ERROR';

export const APP_UPDATE_FEEDBACK_REQUEST = 'APP/UPDATE_FEEDBACK/REQUEST';
export const APP_UPDATE_FEEDBACK_SUCCESS = 'APP/UPDATE_FEEDBACK/SUCCESS';
export const APP_UPDATE_FEEDBACK_ERROR = 'APP/UPDATE_FEEDBACK/ERROR';

export const APP_GET_EVENT_REQUEST = 'APP/GET_EVENT/REQUEST';
export const APP_GET_EVENT_SUCCESS = 'APP/GET_EVENT/SUCCESS';
export const APP_GET_EVENT_ERROR = 'APP/GET_EVENT/ERROR';

export const APP_CREATE_EVENT_REQUEST = 'APP/CREATE_EVENT/REQUEST';
export const APP_CREATE_EVENT_SUCCESS = 'APP/CREATE_EVENT/SUCCESS';
export const APP_CREATE_EVENT_ERROR = 'APP/CREATE_EVENT/ERROR';

export const APP_UPDATE_EVENT_REQUEST = 'APP/UPDATE_EVENT/REQUEST';
export const APP_UPDATE_EVENT_SUCCESS = 'APP/UPDATE_EVENT/SUCCESS';
export const APP_UPDATE_EVENT_ERROR = 'APP/UPDATE_EVENT/ERROR';

export const APP_DELETE_EVENT_REQUEST = 'APP/DELETE_EVENT/REQUEST';
export const APP_DELETE_EVENT_SUCCESS = 'APP/DELETE_EVENT/SUCCESS';
export const APP_DELETE_EVENT_ERROR = 'APP/DELETE_EVENT/ERROR';

export const APP_GET_BUSINESS_REQUEST = 'APP/GET_BUSINESS/REQUEST';
export const APP_GET_BUSINESS_SUCCESS = 'APP/GET_BUSINESS/SUCCESS';
export const APP_GET_BUSINESS_ERROR = 'APP/GET_BUSINESS/ERROR';

export const APP_CREATE_BUSINESS_REQUEST = 'APP/CREATE_BUSINESS/REQUEST';
export const APP_CREATE_BUSINESS_SUCCESS = 'APP/CREATE_BUSINESS/SUCCESS';
export const APP_CREATE_BUSINESS_ERROR = 'APP/CREATE_BUSINESS/ERROR';

export const APP_UPDATE_CENTER_REQUEST = 'APP/UPDATE_CENTER/REQUEST';
export const APP_UPDATE_CENTER_SUCCESS = 'APP/UPDATE_CENTER/SUCCESS';
export const APP_UPDATE_CENTER_ERROR = 'APP/UPDATE_CENTER/ERROR';

export const APP_DELETE_CENTER_REQUEST = 'APP/DELETE_CENTER/REQUEST';
export const APP_DELETE_CENTER_SUCCESS = 'APP/DELETE_CENTER/SUCCESS';
export const APP_DELETE_CENTER_ERROR = 'APP/DELETE_CENTER/ERROR';

export const APP_GET_NOTIFICATION_REQUEST = 'APP/GET_NOTIFICATION/REQUEST';
export const APP_GET_NOTIFICATION_SUCCESS = 'APP/GET_NOTIFICATION/SUCCESS';
export const APP_GET_NOTIFICATION_ERROR = 'APP/GET_NOTIFICATION/ERROR';

export const APP_CREATE_NOTIFICATION_REQUEST = 'APP/CREATE_NOTIFICATION/REQUEST';
export const APP_CREATE_NOTIFICATION_SUCCESS = 'APP/CREATE_NOTIFICATION/SUCCESS';
export const APP_CREATE_NOTIFICATION_ERROR = 'APP/CREATE_NOTIFICATION/ERROR';

export const APP_DELETE_NOTIFICATION_REQUEST = 'APP/DELETE_NOTIFICATION/REQUEST';
export const APP_DELETE_NOTIFICATION_SUCCESS = 'APP/DELETE_NOTIFICATION/SUCCESS';
export const APP_DELETE_NOTIFICATION_ERROR = 'APP/DELETE_NOTIFICATION/ERROR';

export const APP_GET_POST_REQUEST = 'APP/GET_POST/REQUEST';
export const APP_GET_POST_SUCCESS = 'APP/GET_POST/SUCCESS';
export const APP_GET_POST_ERROR = 'APP/GET_POST/ERROR';

export const APP_CREATE_POST_REQUEST = 'APP/CREATE_POST/REQUEST';
export const APP_CREATE_POST_SUCCESS = 'APP/CREATE_POST/SUCCESS';
export const APP_CREATE_POST_ERROR = 'APP/CREATE_POST/ERROR';

export const APP_UPDATE_POST_REQUEST = 'APP/UPDATE_POST/REQUEST';
export const APP_UPDATE_POST_SUCCESS = 'APP/UPDATE_POST/SUCCESS';
export const APP_UPDATE_POST_ERROR = 'APP/UPDATE_POST/ERROR';

export const APP_DELETE_POST_REQUEST = 'APP/DELETE_POST/REQUEST';
export const APP_DELETE_POST_SUCCESS = 'APP/DELETE_POST/SUCCESS';
export const APP_DELETE_POST_ERROR = 'APP/DELETE_POST/ERROR';