import React, { Profiler, useState } from 'react';
import Header from '../../components/Header';
import SideMenu from '../../components/SideMenu';
import './Dashboard.css';
import plus from "../../assets/plus.png"
import newbiz from "../../assets/new-red.png"
import proposal from "../../assets/proposal-yellow.png"
import contract from "../../assets/contract-green.png"
import business from "../../assets/business.png"
import TitleLine from '../../components/DataLine/TitleLine';
import DataLine from '../../components/DataLine/DataLine';
import Pagination from "../../components/Pagination/Pagination"
import { useDispatch, useSelector } from "react-redux"
import { createBusiness } from '../../redux/app/actions';
import { useNavigate } from 'react-router-dom';

const AddBusiness = () => {
  const profile = useSelector((state) => state.App.profile);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [search, setSearch] = useState('')
  const [businessData, setBusinessData] = useState({

      name: "",
      email: "",
      phone: "",
      city: "",
      street: "string",
      state: "string",
      zip_code: "string",
      country: "",
      status: "pending",
      user: profile.id

  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBusinessData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Business data submitted:', businessData);
    dispatch(createBusiness(businessData, navigate))
  };
  

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-content">
      <Header title="Add New Business"
      value={search}
      onChange={e => setSearch(e.target.value)}/>
        <div className="horizontal-rule"></div>
        <div className="content">
        
     
            {/* Left Column */}
            <div className="add-left-side" style={{marginTop: 40, marginRight: 40}}>
            <div className="title">
          New Business 
        </div>
        <div className="subtitle">
        Add details
        </div>
            </div>

            {/* Right Column */}
            <div className="right-columns" >
            
            <div className="add-business-form">
      <form onSubmit={handleSubmit}>
        <div className="add-form-group">
          <label>Business Name:</label>
          <input
            type="text"
            name="name"
            value={businessData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="add-form-group">
          <label>Busniess Email:</label>
          <input
            type="text"
            name="email"
            value={businessData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="add-form-group">
          <label>Busniess Phone:</label>
          <input
            type="text"
            name="phone"
            value={businessData.phone}
            onChange={handleChange}
            required
          />
        </div>
 
        <div className="add-form-group">
          <label>City:</label>
          <input
            type="text"
            name="city"
            value={businessData.city}
            onChange={handleChange}
            required
          />
        </div>

        <div className="add-form-group">
          <label>Country:</label>
          <input
            type="text"
            name="country"
            value={businessData.country}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="add-save-button">
          Save
        </button>
      </form>
    </div>



            </div>

        </div>
      </div>
    </div>
  );
};

export default AddBusiness;
