import React, { useState } from "react"
import Header from "../../components/Header"
import SideMenu from "../../components/SideMenu"
import "./Dashboard.css"
import plus from "../../assets/plus.png"
import newbiz from "../../assets/new-red.png"
import proposal from "../../assets/proposal-yellow.png"
import contract from "../../assets/contract-green.png"
import business from "../../assets/business.png"
import TitleLine from "../../components/DataLine/TitleLine"
import DataLine from "../../components/DataLine/DataLine"
import Pagination from "../../components/Pagination/Pagination"
import sidedot from "../../assets/side-dots.png"
import plusnote from "../../assets/plus-black.png"
import search from "../../assets/search-icon.png"
import trash from "../../assets/trash-white.png"
import bulb from "../../assets/bulb.png"
import ReactQuill from "react-quill"
import { useNavigate } from 'react-router-dom';

const Note = () => {
  const [content, setContent] = useState("")
  const navigate = useNavigate();

  const handleChange = value => {
    setContent(value)
  }
  // Dummy data for tabular display
  const businesses = [
    {
      id: 1,
      name: "ABC Enterprises",
      category: "Retail",
      location: "City A"
    },
    {
      id: 2,
      name: "XYZ Co.",
      category: "Technology",
      location: "City B"
    },
    {
      id: 3,
      name: "Sunrise Cafe",
      category: "Food & Beverage",
      location: "City C"
    },
    {
      id: 4,
      name: "Green Thumb Nursery",
      category: "Gardening",
      location: "City D"
    },
    {
      id: 5,
      name: "Fashion Haven",
      category: "Apparel",
      location: "City A"
    }
  ]

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-content">
        <Header />
        <div className="horizontal-rule"></div>
        <div
          className="contenttt"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
            padding: 20,
            paddingLeft: 80
          }}
        >
          {/* Left Column */}
          <div className="left-side">
            <div className="tabs">
            <button onClick={() => navigate('/add-business')}>
                <img src={plus} alt="Button 1 Image" />
                New Business
              </button>
              <button style={{ backgroundColor: "#ccc", color: "#0F5796" }}>
                <img src={business} alt="Button 1 Image" />
                All
              </button>
              <button style={{ backgroundColor: "#Fff", color: "#FF5B5B" }}>
                <img src={newbiz} alt="Button 1 Image" />
                New
              </button>
              <button style={{ backgroundColor: "#Fff", color: "#FFBB54" }}>
                <img src={proposal} alt="Button 1 Image" />
                Proposal
              </button>
              <button style={{ backgroundColor: "#Fff", color: "#00A389" }}>
                <img src={contract} alt="Button 1 Image" />
                Contract
              </button>
            </div>
          </div>

          <div
            className="left-proposall"
            style={{ padding: 20, flex: 1, margin: 40 }}
          >
            <div className="proposal-content">
              <div style={{ display: "flex" }}>
                <div className="text-container">
                  <p
                    className="proposal-card-title"
                    style={{ marginBottom: 15, fontSize: 24 }}
                  >
                    {"Note Subject"}
                  </p>
                  <p className="card-description" style={{ marginBottom: 25 }}>
                    {"date"}
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#0F5796",
                padding: 20,
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24, color: "#fff" }}
              >
                {"Notes update"}
              </p>
              <img
                src={trash}
                alt="Button 1 Image"
                style={{ width: 20, height: 20 }}
              />
            </div>
            <div style={{ backgroundColor: "#fff", padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20
                }}
              >
                <p
                  className="proposal-card-title"
                  style={{ fontSize: 16, color: "#0F5796" }}
                >
                  {"Title:"}
                </p>
                <div className="search-dropdown">
                  <div className="input-container">
                    <input type="text" placeholder="Contract details" />
                  </div>
                  {/**selectedItem && <p>Selected: {selectedItem}</p>**/}
                </div>
              </div>
              <div className="quill-editor">
                <ReactQuill value={content} onChange={handleChange} />
              </div>
              <div style={{ justifyContent: "flex-end", marginTop: 30 }}>
                <button
                  className="template-button"
                  style={{ backgroundColor: "#0F5796", color: "#fff" }}
                >
                  <img
                    src={bulb}
                    alt="Button 1 Image"
                    style={{ width: 14, height: 14, marginRight: 5 }}
                  />
                  Update
                </button>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="right-columnssss">
            <p style={{ marginTop: 5, fontSize: 14, margin: 20 }}>
              Recent notes
            </p>
            <div style={{ display: "flex", marginBottom: 20, margin: 20 }}>
              <div
                style={{
                  flex: 2,

                  display: "flex",
                  alignItems: "center",
                  position: "relative"
                }}
              >
                <input
                  type="text"
                  placeholder="Search..."
                  style={{
                    width: "100%",
                    height: 26,
                    padding: "8px 30px 8px 8px",
                    border: "none",
                    borderRadius: "10px",
                    backgroundColor: "#F0F0F0"
                  }}
                />
                <img
                  src={search}
                  alt="Search Icon"
                  style={{
                    position: "absolute",
                    width: 20,
                    height: 20,
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                />
              </div>
            </div>
            <div
              className="proposal-right-columnnn"
              style={{
                backgroundColor: "#fff",
                padding: 20,
                borderRadius: 15,
                maxWidth: 250
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <h3
                  style={{
                    color: "#0F5796",
                    fontSize: 24,
                    marginTop: 5,
                    marginBottom: 5
                  }}
                >
                  Notes
                </h3>
                <img
                  src={plusnote}
                  alt={"button"}
                  className="proposal-button-icon"
                  style={{ width: 24, height: 24 }}
                />
              </div>
              <p style={{ marginTop: 5, fontSize: 14 }}>
                Maiores dicta atque dolore
              </p>
              <div className="note">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: 14, marginBottom: 5 }}>
                      Note subject
                    </h5>
                    <p style={{ fontSize: 12, marginTop: 5 }}>Date</p>
                  </div>
                  <img
                    src={sidedot}
                    alt={"button"}
                    className="proposal-button-icon"
                    style={{ width: 14, height: 14 }}
                  />
                </div>
                <h5 style={{ marginBottom: 5, marginTop: 5 }}>
                  Change our contact at Homepage
                </h5>
                <p style={{ fontSize: 12, marginTop: 5 }}>
                  Description: Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit.
                </p>
                <button className="view-button">View</button>
              </div>
              <div className="note">{/* Repeat for other notes */}</div>
              <div className="horizontal-rule"></div>
              <div className="note">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <h5 style={{ fontSize: 14, marginBottom: 5 }}>
                      Note subject
                    </h5>
                    <p style={{ fontSize: 12, marginTop: 5 }}>Date</p>
                  </div>
                  <img
                    src={sidedot}
                    alt={"button"}
                    className="proposal-button-icon"
                    style={{ width: 14, height: 14 }}
                  />
                </div>
                <h5 style={{ marginBottom: 5, marginTop: 5 }}>
                  Change our contact at Homepage
                </h5>
                <p style={{ fontSize: 12, marginTop: 5 }}>
                  Description: Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit.
                </p>
                <button className="view-button">View</button>
              </div>
              <div className="note">{/* Repeat for other notes */}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Note
