import React, { useState } from "react"
import Header from "../../components/Header"
import SideMenu from "../../components/SideMenu"
import "./Proposal.css"
import plus from "../../assets/plus-black.png"
import tag from "../../assets/tag-yellow.png"
import expand from "../../assets/expand.png"
import upload from "../../assets/upload.png"
import searchicon from "../../assets/search-icon.png"
import tick from "../../assets/double-tick.png"
import assign from "../../assets/assign.png"
import share from "../../assets/share.png"
import sign from "../../assets/sign.png"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

import Pagination from "../../components/Pagination/Pagination"

const Template = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(6)
  const [content, setContent] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [search, setSearch] = useState('')

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelect = (item) => {
    setSelectedItem(item);
    setIsDropdownOpen(false);
  };

  const handleChange = (value) => {
    setContent(value);
  };
  // Dummy data for tabular display
  const businesses = [
    {
      id: 1,
      name: "ABC Enterprises",
      category: "Retail",
      location: "City A"
    },
    {
      id: 1,
      name: "ABC Enterprises",
      category: "Retail",
      location: "City A"
    },
    {
      id: 1,
      name: "ABC Enterprises",
      category: "Retail",
      location: "City A"
    }
  ]

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-content">
      <Header title="Proposal Templates"
      value={search}
      onChange={e => setSearch(e.target.value)}/>

        <div className="horizontal-rule"></div>
        <div className="create-content">
        <div className="create-left-proposal" >
          <div className="proposal-content" >
                      <div style={{ display: "flex" }}>
                        
                        <div className="text-container">
                          <p className="proposal-card-title" style={{marginBottom: 15, }}>
                          Proposal template name
                          </p>
                          <p className="card-description" style={{marginBottom: 25}}>
                            {
                              "template"
                            }
                          </p>
                        </div>
                      </div>
                      <div className="expand-icon">
                        <img
                          src={sign}
                          alt={"item"}
                          className="card-image"
                          style={{width: 18, height: 18}}
                        />
                        <img
                          src={share}
                          alt={"item"}
                          className="card-image"
                          style={{width: 18, height: 18}}
                        />
                        <img
                          src={assign}
                          alt={"item"}
                          className="card-image"
                          style={{width: 18, height: 18}}
                        />
                      </div>
                    </div>
  <div style={{backgroundColor: "#fff", padding: 40, }}>
<div style={{display: "flex", justifyContent: "space-between", marginBottom: 40}}>
<img src={upload} style={{width: 24, height: 24, marginRight: 5, alignSelf: "center", marginTop: 0}} alt="Button 1 Image" />
  <button className="template-button" style={{ backgroundColor: "#E5EDF3", color: "#0F5796", width: 120, height: 40,  }}>
  <img src={tick} style={{width: 14, height: 14, marginRight: 5, alignSelf: "center", marginTop: 0}} alt="Button 1 Image" />Finish</button>
</div>
    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", }}>
          <p className="proposal-card-title" style={{fontSize: 16}}>
                            {"Template name:"}
                          </p>
                          <div className="search-dropdown">
      <div className="input-container">
        <input type="text" placeholder="Text input" />

        <button className="plus-button" onClick={toggleDropdown}>
            <img src={plus} style={{width: 14, height: 14, marginRight: 5}} alt="Button 1 Image" />
           Add industry
          </button>

        {isDropdownOpen && (
          <div className="dropdown">
            <button onClick={() => handleSelect('Industry 1')}>Option 1</button>
            <button onClick={() => handleSelect('Industry 2')}>Option 2</button>
            <button onClick={() => handleSelect('Industry 3')}>Option 3</button>
          </div>
        )}
      </div>
      {/**selectedItem && <p>Selected: {selectedItem}</p>**/}
    </div>
    </div>
          <div className="quill-editor">
      <ReactQuill value={content} onChange={handleChange} />
    </div>
    
    </div>
          </div>

          {/* Right Column */}
          <div className="create-right-column">
            <div style={{ display: "flex", marginBottom: 20 }}>
              <div
                style={{
                  flex: 2,

                  display: "flex",
                  alignItems: "center",
                  position: "relative"
                }}
              >
                <input
                  type="text"
                  placeholder="Search..."
                  style={{
                    width: "100%",
                    height: 26,
                    padding: "8px 30px 8px 8px",
                    border: "none",
                    borderRadius: "10px",
                    backgroundColor: "#F0F0F0"
                  }}
                />
                <img
                  src={searchicon}
                  alt="Search Icon"
                  style={{
                    position: "absolute",
                    width: 20,
                    height: 20,
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                />
              </div>
             
            </div>

            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 10, margin: 10}}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <h3 style={{color: "#0F5796", fontSize: 24, marginTop: 5, marginBottom: 5}}>Notes</h3>
        <img src={plus} alt={"button"} className="proposal-button-icon" style={{width: 24, height: 24}} />
        </div>
        <p style={{marginTop: 5, fontSize: 14}}>Maiores dicta atque dolore</p>
            </div>

         {/**  <div className="quill-editor">
      <ReactQuill value={content} onChange={handleChange} />
                </div>  */}

            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 10, margin: 10}}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <h3 style={{color: "#0F5796", fontSize: 24, marginTop: 5, marginBottom: 5}}>Proposal</h3>
        <img src={plus} alt={"button"} className="proposal-button-icon" style={{width: 24, height: 24}} />
        </div>
        <p style={{marginTop: 5, fontSize: 14}}>Add new template</p>
            </div>

            {businesses?.length
              ? businesses.map(item => (
                  <div className="proposal-card" style={{height: 150}}>
                    <div className="proposal-content" style={{ display: "flex", alignItems: "center",  justifyContent: "space-between" }}>
                      
                        <div className="proposal-image-container" style={{height: 50}}>
                          {/**<img src={proposal} alt={item.name} className="card-image" />**/}
                        </div>
                        <div className="text-container">
                          <p className="proposal-card-title">
                            {"Proposal template name"}
                          </p>
                          
                        </div>
                        <div className="expand-icon">
                        <img
                          src={expand}
                          alt={item.name}
                          className="card-image"
                          style={{marginBottom: 10}}
                        />
                      </div>
                     
                      
                     
                    </div>
                    <p className="card-description">
                            {
                              "Architecto consequatur molestias repellat qui. Quia est asd doloremque veniam est rerum. Soluta "
                            }
                          </p>
                    <div className="proposal-buttons">
                      <button className="industry-button"
                      style={{backgroundColor: "#fff"}}>
                        
                        Industry
                      </button>
                      <button className="template-button"
                      style={{backgroundColor: "#fff"}}>Use Template</button>
                    </div>
                  </div>
                ))
              : null}

            <Pagination
              //className="pagination-bar"
              create={true}
              currentPage={currentPage}
              totalCount={businesses.length}
              pageSize={pageSize}
              onPageChange={page => setCurrentPage(page)}
              onSizeChange={size => setPageSize(size)}
            />
          </div>

          
        </div>
      </div>
    </div>
  )
}

export default Template
