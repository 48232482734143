import React, { useEffect, useState } from "react"
import "./App.css"

import { Provider } from "react-redux"
import { store } from './redux/store'
import { setupHttpConfig } from "./utils/http"
import Navigation from "./Navigation"

function App() {

  useEffect(() => {
    setupHttpConfig()
  }, [])



  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  )
}

export default App
