import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../components/Header';
import SideMenu from '../../components/SideMenu';
import './Dashboard.css';
import plus from "../../assets/plus.png"
import newbiz from "../../assets/new-red.png"
import proposal from "../../assets/proposal-yellow.png"
import contract from "../../assets/contract-green.png"
import business from "../../assets/business.png"
import TitleLine from '../../components/DataLine/TitleLine';
import DataLine from '../../components/DataLine/DataLine';
import Pagination from "../../components/Pagination/Pagination"
import { useNavigate } from 'react-router-dom';
import { getBusinessList } from '../../redux/app/actions';
import { useDispatch, useSelector } from "react-redux"
import Loader from '../../components/loader/Loader';

const Dashboard = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [isChecked, setIsChecked] = useState(false);
  const [search, setSearch] = useState('')
  const navigate = useNavigate();
  const businesses = useSelector(state => state.App.businesses)
  // const isLoading = useSelector(state => state.App.isLoading)

  const isBusinessesAvailable = Array.isArray(businesses);

  //console.log(businesses)


  useEffect(() => {
    dispatch(getBusinessList())
    //dispatch(getProfile())
    //dispatch(getCenterList())
  }, [])

  const list =  businesses?.filter((o) => o?.email?.includes(search) || o?.name?.includes(search))
  const listPrint =  search ? list :  businesses

  const currentTableData = useMemo(() => {
    if (!listPrint) {
      return [];
    }
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return listPrint.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, listPrint]);

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-content">
      <Header title="New Business Management"
      value={search}
      onChange={e => setSearch(e.target.value)}/>
        <div className="horizontal-rule"></div>
        <div className="content">
        
     
            {/* Left Column */}
            <div className="left-side">
             
              <div className="tabs">
              <button onClick={() => navigate('/add-business')}>
            <img src={plus} alt="Button 1 Image" />
            New Business
          </button>
          <button style={{backgroundColor: "#ccc", color: "#0F5796"}}>
            <img src={business}  alt="Button 1 Image" />
            All
          </button>
          <button style={{backgroundColor: "#Fff", color: "#FF5B5B"}}>
            <img src={newbiz} alt="Button 1 Image" />
            New 
          </button>
          <button style={{backgroundColor: "#Fff", color: "#FFBB54"}}>
            <img src={proposal} alt="Button 1 Image" />
            Proposal
          </button>
          <button style={{backgroundColor: "#Fff", color: "#00A389"}}>
            <img src={contract} alt="Button 1 Image" />
            Contract
          </button>
              </div>
            </div>

            {/* Right Column */}
            <div className="right-columns">
            <div className="business-management-header">
      <div className="header-content">
        <div className="title">
          New Business Management
        </div>
        <div className="subtitle">
          List of all businesses
        </div>
      </div>
      <button className="all-button">
      <img src={business}  alt="Button 1 Image" />All</button>
    </div>


         <TitleLine />   
         {isBusinessesAvailable
              ? currentTableData.map((item) => (
                  <DataLine
                    item={item}
                    checked={isChecked}
                    isChecked={() =>
                      setIsChecked(isChecked === item.id ? false : item.id)
                    }
                    navigate={navigate}
                  />
                ))
              : null}
                

<Pagination
        //className="pagination-bar"
        currentPage={currentPage}
        totalCount={isBusinessesAvailable ? businesses.length : 0}
        totalPages={Math.ceil(isBusinessesAvailable ? businesses.length / pageSize : 0)}
        pageSize={pageSize}
        onPageChange={page => setCurrentPage(page)}
        onSizeChange={size => setPageSize(size)}
      />



            </div>

        </div>
      </div>
    </div>
  );
};

export default Dashboard;
