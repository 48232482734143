import React, { useState } from "react"
import "./Login.css"
import eye from "../../assets/eye.png"
import { Link } from "react-router-dom"
import AppModal from "../../components/Modal"
import { useDispatch } from 'react-redux';
import { signUp } from "../../redux/auth/actions"

const SignUp = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [showConfrim, setShowConfirm] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [userData, setUserData] = useState({
    name: '',
    location: '',
    industry: '',
    description: '',
    contact: '',
    confirm_password: "",
    password: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = e => {
    e.preventDefault()
    // Handle the login logic here, e.g., submit the login credentials to the server
    console.log('User data submitted:', userData);
    dispatch(signUp(userData))
    setShowPopup(true)
  }



  const handleCheckboxChange = e => {
    setIsChecked(e.target.checked)
  }

  const handleClosePopup = () => {
    setShowPopup(false) // Close the pop-up when the user clicks the close button
  }

  return (
    <div className="login-screen">
    
      <header className="header-login">
        <h1>Logo</h1>
        <span className="create-account-text">
          Already have an account?{" "}
          <span className="create-text">
            <Link to="/Login">
              <a href="#">Log in</a>
            </Link>
          </span>
        </span>
      </header>
  
      <div className="form-containers">
        <form onSubmit={handleLogin} className="login-form">
          <h2>Sign up</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis,
            ac ac turpis viverra
          </p>
          <div className="form-group">
            <input
              type="text"
              id="agency"
              value={userData.name}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["name"]: e.target.value }))}
              placeholder="Agency / Company name"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              value={userData.email}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["email"]: e.target.value }))}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-group">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={userData.password}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["password"]: e.target.value }))}
              placeholder="Enter your password"
              required
            />
            <img
              src={eye}
              className="eye-icon"
              alt="eye"
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
          <div className="form-group">
            <input
              type={showConfrim ? "text" : "password"}
              id="password"
              value={userData.confirm_password}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["confirm_password"]: e.target.value }))}
              placeholder="Confirm password"
              required
            />
            <img
              src={eye}
              className="eye-icon"
              alt="eye"
              onClick={() => setShowConfirm(!showConfrim)}
            />
          </div>
          <div className="form-duo">
            <input
              type="text"
              id="email"
              value={userData.contact}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["contact"]: e.target.value }))}
              placeholder="Contact"
              required
            />
            <input
              type="text"
              id="email"
              value={userData.location}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["location"]: e.target.value }))}
              placeholder="Location"
              required
            />
          </div>
       
          
          <div className="sign-checkbox-container">
          
          <label style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'start', marginRight: 150,  }}>
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                style={{ marginRight: '5px' }}
              />
              <span style={{ flex: 'none' }}>
              Agree to <a href="#">Terms & Conditions</a> and{" "}
              <a href="#">Privacy Policy</a>
              </span>
            </label>
          </div>
          <button className="login-button" type="submit">
            Login
          </button>
          
        </form>
       
        
      </div>
      
      

      {showPopup && (
        <AppModal
          modalIsOpen={showPopup}
          signup={true}
          values={userData}
          closeModal={handleClosePopup}
          onSubmit={handleClosePopup}
        />
      )}
    </div>
  )
}

export default SignUp
